import {
  CaretLeftIcon,
  IconButton,
  IconButtonLink,
  IconButtonLinkIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { Link } from "@tanstack/react-router"
import type {
  ReactNode,
  MouseEventHandler,
} from "react"
import styles from "./PublicPageHeader.module.scss"
import type { RoutePath } from "src/types"

interface Props {
  backRoutePath?: RoutePath;
  onBackClick?: MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
}

export function PublicPageHeader(props: Props) {
  const {
    backRoutePath,
    onBackClick,
  } = props

  return (
    <div className={styles.container}>
      <IconButtonLink
        className={styles.backButton}
        stylePreset="withoutBackground"
        asChild
        id="backButton"
      >
        {onBackClick ? (
          <IconButton onClick={onBackClick}>
            <CaretLeftIcon className={styles.backButtonIcon }/>
          </IconButton>
        ) : (
          <Link
            to={backRoutePath}
            params={{}}
          >
            <IconButtonLinkIcon>
              <CaretLeftIcon className={styles.backButtonIcon }/>
            </IconButtonLinkIcon>
          </Link>
        )}
      </IconButtonLink>
      <div className={styles.centerText}>
        <div className={styles.title} >
          {props.children}
        </div>
      </div>
    </div>
  )
}
