import {
  SugarmateLogoIcon,
  SugarmateLogoText,
} from "@tandemdiabetes/sugarmate-react-components"
import { Link } from "@tanstack/react-router"
import styles from "./IndexPage.module.scss"
import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.index."

export function IndexPage() {
  const polyglot = usePolyglot()

  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <SugarmateLogoIcon className={styles.logoIcon} />
        <SugarmateLogoText className={styles.logoText} />
      </div>
      <div>
        <Link
          to="/sign_in"
          className={styles.link}
          id="signInButton"
        >
          {polyglot.t(`${polyglotPrefix}sign_in_link`)}
        </Link>
        <Link
          to="/sign_up"
          className={styles.link}
          id="signUpButton"
        >
          {polyglot.t(`${polyglotPrefix}sign_up_link`)}
        </Link>
      </div>
    </div>
  )
}
