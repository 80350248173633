import {
  AtomButton,
  IconButton,
  PencilIcon,
} from "@tandemdiabetes/sugarmate-react-components"
import { useCallback } from "react"

import { useInsightsContext } from "./InsightsContext"
import { usePolyglot } from "src/contexts"

const polyglotPrefix = "pages.home.insights."

export function InsightsEditButton() {
  const {
    isEditing,
    setIsEditing,
  } = useInsightsContext()

  const polyglot = usePolyglot()

  const startEditing = useCallback(
    () => {
      setIsEditing(true)
    },
    [setIsEditing],
  )

  const stopEditing = useCallback(
    () => {
      setIsEditing(false)
    },
    [setIsEditing],
  )

  return isEditing ? (
    <AtomButton onClick={stopEditing}>
      {polyglot.t(`${polyglotPrefix}header_done_cta`)}
    </AtomButton>
  ) : (
    <IconButton
      onClick={startEditing}
      stylePreset="withoutBackground"
      id="editInsightsButton"
    >
      <PencilIcon />
    </IconButton>
  )
}
